import React, { useEffect, useRef, useState } from 'react';
import { child, getDatabase, ref, set, onValue } from "firebase/database";
import { createUserWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { auth, db} from "../../firebase/FireBase.js"


import logo from "../../images/logo.png"

import Leaflet from "../../utils/LeafLet.js"
import JobSelector from "./JobSelector.js"
import AddNewDevice from './AddNewDevice.js';
import AddOkka from './AddOkka.js';
import AddPriceToLocation from './AddPriceToLocation.js';
import LailaService from './LailaService.js';
import AddCupPriceToLocation from './AddCupPriceToLocation.js';
import AddNewQrDevice from './AddNewQrDevice.js';
import AddStockToLoc from "./AddStockToLoc.js"
import QRScanner from './AddCoffeSet.js';
import DeleteQR from './DeleteQR.js';


const Technicians = ({ setIsLoggedIn }) => {

    const [successModal, setSuccessModal] = useState(false);
    const [errorModal, setErrorModal] = useState(false);
    const [errorModal2, setErrorModal2] = useState(false);
    const [padding, setPadding] = useState(false)


    const [job, setJob] = useState(null)
    const [userPositions, setuserPositions] = useState(null)
    const [locations, setLocations] = useState(null)
    const locationName = useRef()
    const locationOwnerName = useRef()
    const locationOwnerEmail = useRef()
    const locationCountry = useRef()
    const locationCity = useRef()
    const sendDataRef = useRef()
    const [data, setData] = useState(null)

    useEffect(() => {
        if (job == 3) {
            sessionStorage.setItem("job", `${job}`)
            console.log(job, sessionStorage.getItem("job"))

        } else if (job != undefined) {
            sessionStorage.removeItem("job");
        }
        //console.warn(job, sessionStorage.getItem("job"))


    }, [job])

    useEffect(() => {
        if (sessionStorage.getItem("job") == "3") {
            setJob(Number(sessionStorage.getItem("job")))
        }

        //console.warn(job, sessionStorage.getItem("job"))
    }, [])

    window.onkeydown = (event) => {
        if (event.key === "Enter") {
            event.preventDefault()
            sendDataRef?.current.click()
        }
    }

    const locationId = new Date().getTime();

    const dbRefLoc = child(ref(db), `locations/`);
    useEffect(() => {
        onValue(dbRefLoc, (snapshot) => {
            const data = snapshot.val();
            setLocations(data);
        });
    }, [])

    useEffect(() => {
        //console.warn(locations)
    }, [locations])


    const [userCreds, setUserCreds] = useState(null)

    const dbRefUsersCreds = child(ref(db), `usersCreds/`);
    useEffect(() => {
        onValue(dbRefUsersCreds, (snapshot) => {
            if (snapshot.val()) {
                let data = snapshot.val();
                data = Object.values(data)
                setUserCreds(data);
            }
        });
    }, [])

    useEffect(() => {
        //console.warn("usercreds", userCreds)
    }, [userCreds])

    useEffect(() => {
        if (userPositions && userPositions.country !== undefined) {
            locationCountry.current.value = userPositions.country;
        }
        if (userPositions && userPositions.city !== undefined) {
            locationCity.current.value = userPositions.city;
        }
    }, [userPositions]);

    const date = new Date()


    const sendData = () => {

        if (!locationName.current.value || !locationOwnerEmail.current.value) {
            
            setErrorModal(true)
            return
        }
        setPadding(true)
        let isThere = false
        userCreds?.forEach((userCred) => {
            if (userCred.email == locationOwnerEmail.current.value) {
                isThere = true
            }
        })
        //console.error(isThere)
        if (!isThere) {
            let user = null
            console.log("creating user")
            createUserWithEmailAndPassword(auth, locationOwnerEmail.current.value, "123456")
                .then((userCredential) => {
                    sendPasswordResetEmail(auth, locationOwnerEmail.current.value)
                        .then(() => {
                        })
                        .catch((error) => {
                            
                            setErrorModal(true)
                        });
                    user = userCredential.user;

                    // set new user email to new location
                    const dbRefLocations = child(ref(db), `locations/` + `loc_${locationId}/`);
                    set((dbRefLocations), {
                        city: locationCity.current.value,
                        country: locationCountry.current.value,
                        location_id: "loc_" + locationId,
                        location_name: `${locationName.current.value}`,
                        location_coords: userPositions ? { "0": userPositions.cords.x, "1": userPositions.cords.y } : { "0": "null", "1": "null" },
                        sell_price: 0,
                        customers: true
                    }).then(() => {
                            const dbRefLocationsUsers = child(ref(db), `locations/` + `loc_${locationId}/` + "users/" + `${user.uid}`);
                            set((dbRefLocationsUsers), {
                                valid: true,
                                Role: "Owner",
                                NameAndSurName: locationOwnerName.current.value
                            })
                            const dbRefUsers = child(ref(db), `users/` + `${user.uid}/`);
                            set((dbRefUsers), {
                                valid: true
                            }).then(() => {
                                const dbRefUsersLocations = child(ref(db), `users/` + `${user.uid}/` + "locations/" + `loc_${locationId}`);
                                set((dbRefUsersLocations), true).then(() => {
                                }).catch((error) => {
                                    
                                    setErrorModal(true)
                                });
                                const dbRefSessionsCompleted = child(ref(db), `locations/` + `loc_${locationId}/` + "sessions_completed/");
                                set((dbRefSessionsCompleted), {
                                    count:0
                                }).then(() => {
                                }).catch((error) => {
                                    
                                    setErrorModal(true)
                                });
                            }).catch((error) => {
                                    
                                    setErrorModal(true)
                                    //console.log(error)
                            });
                    }).catch((error) => {
                            
                            setErrorModal(true)
                            //console.log(error)

                        });
                    // --------------------------------------------------------------------------

                    // Push user creds to realtime-database too AS usersCreds
                    const dbRefUsersCredsWithEmail = child(ref(db), `usersCreds/` + `${user.uid}`);
                    set((dbRefUsersCredsWithEmail), {
                        email: `${user.email}`,
                        uid: `${user.uid}`,
                        registerTime: date.getTime(),
                        Role: "Owner"
                    }).then(() => {
                            setSuccessModal(true)
                            setPadding(false)

                    }).catch((error) => {
                            //console.error(error.message)
                            console.log(error.message)
                            setErrorModal(true)
                            setPadding(false)

                        });
                    // -----------------------------------------------------



                }).catch((error) => {
                    // const errorCode = error.code;
                    //console.log(error)
                    setErrorModal(true)
                    //console.log(error.message)

                    // const errorMessage = error.message;
                    // ..
                })
        } else {
            alert("user is already there")
            let myUser = userCreds.filter(user => user.email == locationOwnerEmail.current.value)
            //console.log(myUser)
            const dbRefLocations = child(ref(db), `locations/` + `loc_${locationId}/`);
            set((dbRefLocations), {
                city: (typeof userPositions.city != undefined ? userPositions.city : userPositions.city_district),
                country: `${userPositions.country}`,
                location_id: "loc_" + locationId,
                location_name: `${locationName.current.value}`,
                location_coords: userPositions ? { "0": userPositions.cords.x, "1": userPositions.cords.y } : { "0": "null", "1": "null" },
                sell_price: 10,

            }).then(() => {

                const dbRefUsers = child(ref(db), `locations/` + `loc_${locationId}/` + "users/" + `${myUser[0].uid}`);
                set((dbRefUsers), true)
            })
            const dbRefUsersLocations = child(ref(db), `users/` + `${myUser[0].uid}/` + "locations/" + `loc_${locationId}`);
            set((dbRefUsersLocations), true).then(() => {
                    console.warn("saved")
                    setSuccessModal(true)
            }).catch((error) => {
                    //console.error(error.message)
                    setErrorModal(true)
                    console.warn(error)

                });
        }
        // --------------------------------------------------

    }

    const signOut = () => {
        setIsLoggedIn("false")
        sessionStorage.clear()
    }

    const pageRefresh = () => {
        window.location.reload()
    }
    return (
        <div id="tech-page-wrapper">
            <div id="myModal" className={errorModal ? "modal fade show" : "modal fade"}>
                <div className="modal-dialog modal-confirm">
                    <div className="modal-content">
                        <div className="modal-header justify-content-center">
                            <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48"><path d="m330 768 150-150 150 150 42-42-150-150 150-150-42-42-150 150-150-150-42 42 150 150-150 150 42 42Zm150 208q-82 0-155-31.5t-127.5-86Q143 804 111.5 731T80 576q0-83 31.5-156t86-127Q252 239 325 207.5T480 176q83 0 156 31.5T763 293q54 54 85.5 127T880 576q0 82-31.5 155T763 858.5q-54 54.5-127 86T480 976Zm0-60q142 0 241-99.5T820 576q0-142-99-241t-241-99q-141 0-240.5 99T140 576q0 141 99.5 240.5T480 916Zm0-340Z" /></svg>
                            <button type="button" className="close" onClick={() => setErrorModal(false)} data-dismiss="modal" aria-hidden="true">&times;</button>
                        </div>
                        <div className="modal-body text-center">
                            <h4>Ooops!</h4>
                            <p>Something went wrong.</p>
                            <button className="btn btn-success" onClick={sendData} data-dismiss="modal">Try Again</button>
                        </div>
                    </div>
                </div>
            </div>
            <div id="myModal" className={errorModal2 ? "modal fade show" : "modal fade"}>
                <div className="modal-dialog modal-confirm">
                    <div className="modal-content">
                        <div className="modal-header justify-content-center">
                            <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48"><path d="m330 768 150-150 150 150 42-42-150-150 150-150-42-42-150 150-150-150-42 42 150 150-150 150 42 42Zm150 208q-82 0-155-31.5t-127.5-86Q143 804 111.5 731T80 576q0-83 31.5-156t86-127Q252 239 325 207.5T480 176q83 0 156 31.5T763 293q54 54 85.5 127T880 576q0 82-31.5 155T763 858.5q-54 54.5-127 86T480 976Zm0-60q142 0 241-99.5T820 576q0-142-99-241t-241-99q-141 0-240.5 99T140 576q0 141 99.5 240.5T480 916Zm0-340Z" /></svg>
                            <button type="button" className="close" onClick={() => setErrorModal2(false)} data-dismiss="modal" aria-hidden="true">&times;</button>
                        </div>
                        <div className="modal-body text-center">
                            <h4>Ooops!</h4>
                            <p>There is an exisiting location using this email address. Please try another email account to create a new location.</p>
                            <button className="btn btn-success" onClick={sendData} data-dismiss="modal">Try Again</button>
                        </div>
                    </div>
                </div>
            </div>
            <div id="myModal" className={successModal ? "modal success fade show" : "modal success fade"}>
                <div className="modal-dialog modal-confirm">
                    <div className="modal-content">
                        <div className="modal-header justify-content-center">
                            <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48"><path d="M378 810 154 586l43-43 181 181 384-384 43 43-427 427Z" /></svg>
                            <button type="button" className="close" onClick={() => window.location.reload()} data-dismiss="modal" aria-hidden="true">&times;</button>
                        </div>
                        <div className="modal-body text-center">
                            <h4>Great!</h4>
                            <p>Location has been successfully added.</p>
                            <button className="btn btn-success" onClick={() => window.location.reload()} data-dismiss="modal">Ok</button>
                        </div>
                    </div>
                </div>
            </div>




            <div id='tech-header'>
                <div onClick={pageRefresh} className="brand-logo">
                    <img src={logo}></img>
                </div>
                <div onClick={signOut} className='header-boxes'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z" /></svg>
                </div>
            </div>
            <div className='label--input'>
                <label>Actions</label>
                <JobSelector job={job} setJob={setJob} />
            </div>
            {
                job == 0 ?
                    <div id='addLocation'>
                        <div className='label--input'>
                            <label>Location Name</label>
                            <input ref={locationName} />
                        </div>
                        <Leaflet setuserPositions={setuserPositions} />
                        <div className='label--input'>
                            <label>Location Owner Name</label>
                            <input ref={locationOwnerName} />
                        </div>
                        <div className='label--input'>
                            <label>Location Owner Email</label>
                            <input ref={locationOwnerEmail} />
                        </div>
                        <div className='label--input'>
                            <label>Country</label>
                            <input ref={locationCountry} />
                        </div>
                        <div className='label--input'>
                            <label>City</label>
                            <input ref={locationCity} />
                        </div>
                        <button ref={sendDataRef} className={padding ? 'sendDataButton padding' : 'sendDataButton'} onClick={sendData}>Add location</button>
                        {/* <p>{data}</p> */}
                    </div> :
                    job == 1 ?
                      <AddNewDevice /> :
                        job == 2 ?
                          <AddOkka/> :
                                job == 3 ?
                                  <AddPriceToLocation/> :
                                    job == 4 ?
                                      <AddCupPriceToLocation /> :
                                        job == 5 ?
                                        <LailaService/> :
                                            job == 6 ?
                                            <AddStockToLoc /> :
                                                job == 7 ?
                                                    <QRScanner /> :
                                                    job == 8 ?
                                                        <DeleteQR /> :
                                                        job == 9 ?
                                                            <AddNewQrDevice /> :
                                                              null
            }

        </div>
    )
}

export default Technicians