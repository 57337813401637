import DatePicker from "react-datepicker";
import Select from "react-select";
import { auth, db } from "../../firebase/FireBase.js";
import { update } from "firebase/database";
import SelectorInputCity from "../../components/SelectorInputCity.js";
import SelectorInputLocal from "../../components/SelectorInputLocal.js";
import SelectorInputShop from "../../components/SelectorInputShop.js";
import logo from "../../images/logo.png";
import "../../../src/style.css";

import { useEffect, useState } from "react";
import { dbRef, dbRefDevice } from "../../firebase/FireBase.js";

import {
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import { child, onValue, ref, set } from "firebase/database";

import { LineChart } from "../../utils/LineChart.jsx";
import { LineChartCount } from "../../utils/lineChartCount.jsx";

import DataRange from "../../utils/DataRange.js";
import CardBox from "../../../src/utils/CardBox";

import { svgs } from "../../utils/svgs.js";


function Admin({ userCred, isLoggedIn, setIsLoggedIn }) {
  const [allData, setAllData] = useState([]);

  const [devices, setDevices] = useState([]);

  const [cities, setCities] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);

  const [locals, setLocals] = useState([]);
  const [selectedLocals, setSelectedLocals] = useState([]);

  const [shops, setShops] = useState([]);
  const [selectedShop, setSelectedShop] = useState([]);

  const [userLocations, setUserLocations] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [dataRange, setDataRange] = useState({
    start: new Date(),
    end: new Date(),
    option: 0,
  });

  const [isCustomRange, setIsCustomRange] = useState(false);

  const [errorModal, setErrorModal] = useState(false);
  const [errorModalMessage, setErrorModalMessage] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [successModalMessage, setSuccessModalMessage] = useState(false);

  const [selectedTimeProfit, setSelectedTimeProfit] = useState(0);
  const [selectedTimeCount, setSelectedTimeCount] = useState(0);

  const [devicesForMin, setDevicesForMin] = useState(null);
  const [minTime, setMinTime] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);
  const [countryOption, setCountryOption] = useState([]);
  const [userCountry, setUserCountry] = useState(null);
  const [users, setUsers] = useState();
  const [locations, setLocations] = useState([]);
  const [allLocations, setAllLocations] = useState([]);
  // -------------------------------------------------
  const [totalProductionState, setTotalProductionState] = useState(0);
  const [sessionsCompletedState, setSessionsCompletedState] = useState(0);
  const [cupsSoldState, setCupsSoldState] = useState(0);
  const [revenueSessionsState, setRevenueSessionsState] = useState(0);
  const [revenueOfCupsState, setRevenueOfCupsState] = useState(0);
  const [totalLailaSessionsState, setTotalLailaSessionsState] = useState(0);
  const [totalLailaRevenueState, setTotalLailaRevenueState] = useState(0);
  const [totalLailaCostState, setTotalLailaCostState] = useState(0);
  const [lailaNetIncomeState, setLailaNetIncomeState] = useState(0);
  const [hasLailaService, setHasLailaService] = useState(false);
  const [currency, setCurrency] = useState('€');
  
  // -------------------------------------------------

  useEffect(() => {
    onValue(dbRefDevice, (snapshot) => {
      if (snapshot.exists()) {
        setDevicesForMin(Object.entries(snapshot.val()));
      }
    });
  }, []);

  useEffect(() => {
    // if ()
    let timeStamps = [];
    devicesForMin?.forEach(async (device) => {
      // to caculate sum of interval_production for both Button1 & Button2
      // check if it is not an old device
      if (device[0] != 'E0:5A:1B:6C:6E:E8' && device[0] != '94:E6:86:02:87:28' && device[0] != 'E0:5A:1B:6C:9E:0C') {
        let Button1_Count = 0;
        let Button2_Count = 0;
        
        if (device[1]?.Button1?.Timestamp != undefined && device[1]?.Button1?.Timestamp ) {

          Object.values(device[1]?.Button1?.Timestamp).forEach((timeStamp) =>{
            Button1_Count += timeStamp.Interval_production;
          });

          if (typeof device[0] != "undefined") {
            // finally updating sessions counts
            const dbRefDevicesButton1Count = child(
              ref(db),
              "devices/" + device[0] + "/Button1/"
            );
            await update(dbRefDevicesButton1Count, {
              Count: Button1_Count,
            }).then(() => {
              // console.log(loc_[1].location_id + " -> " +sessionsCounter + "counts synced");
            });
          }

        }

        if (device[1]?.Button2?.Timestamp != undefined && device[1]?.Button2?.Timestamp ) {

          Object.values(device[1]?.Button2?.Timestamp).forEach((timeStamp) => {
            Button2_Count += timeStamp.Interval_production;
          })

          if (typeof device[0] != "undefined") {
            // finally updating sessions counts
            const dbRefDevicesButton1Count = child(
              ref(db),
              "devices/" + device[0] + "/Button2/"
            );
            await update(dbRefDevicesButton1Count, {
              Count: Button2_Count,
            }).then(() => {
              // console.log(loc_[1].location_id + " -> " +sessionsCounter + "counts synced");
            });
          }

        }
        console.log("Button1 total Count: "+device[0] + " -> "+Button1_Count);
        
        // console.log("Button2 total Count: "+device[0] + " -> "+Button2_Count);
        
      }

      if (device[1]?.Button1?.Timestamp != undefined && device[1]?.Button2?.Timestamp != undefined) {
        Object.values(device[1]?.Button1?.Timestamp).forEach((timeStamp) =>
          timeStamps.push(timeStamp?.Ts)
        );
        Object.values(device[1]?.Button2?.Timestamp).forEach((timeStamp) =>
          timeStamps.push(timeStamp?.Ts)
        );
      }

    });


    function getSmallestNumber(numbers) {
      let smallest = numbers[0];
      for (let i = 1; i < numbers.length; i++) {
        if (numbers[i] < smallest) {
          smallest = numbers[i];
        }
      }
      return smallest;
    }
    let min = getSmallestNumber(timeStamps);

    setMinTime(min);
  }, [devicesForMin]);

  useEffect(() => {
    setStartDate(dataRange.start);
    setEndDate(dataRange.end);
    // console.warn("++++++++++", dataRange.start, dataRange.end)
  }, [dataRange]);

  useEffect(() => {
    if (startDate > endDate) {
      setErrorModalMessage("Start Date cannot be bigger than End Date");
      setErrorModal(true);
      setStartDate(new Date(new Date() - new Date(2502152000)));
      setEndDate(new Date());
    }
  }, [startDate, endDate]);

  let tempCities = [];

  useEffect(() => {
    if (isLoggedIn == "user" || isLoggedIn == "owner") {
      const dbRefUser = child(ref(db), `users/` + `${userCred?.uid}`);
      onValue(dbRefUser, (snapshot) => {
        if (snapshot.exists()) {
          setUsers(Object.entries(snapshot.val()));
        } else {
        }
      });
    }
    if (isLoggedIn == "admin") {
      const dbRefCountries = child(ref(db), `valid-countries/`);
      onValue(dbRefCountries, (snapshot) => {
        if (snapshot.exists()) {
          let countOptions = [];
          for (const [key, value] of Object.entries(snapshot.val())) {
            countOptions.push({ value: key, label: value });
            // console.log(`${key}: ${value}`);
          }
          setCountryOption(countOptions);
        } else {
        }
      });
    }
  }, [userCred]);

  useEffect(() => {
    onValue(dbRef, (snapshot) => {
      if (snapshot.exists()) {
        setAllLocations(Object.entries(snapshot.val()));
        // setting only those locations where admin have same Country as defined in userCred
        if (isLoggedIn == "admin" && userCred) {
          let adminLocations;
          const dbRefAdmin = child(ref(db), `usersCreds/` + `${userCred?.uid}`);
            onValue(dbRefAdmin, (adminSnapshot) => {
              if (snapshot.exists()) {
                let currentAdmin = adminSnapshot.val();
                if(currentAdmin.Country != "Global"){
                  adminLocations = Object.entries(snapshot.val()).filter((loc) => loc[1].country === currentAdmin.Country);
                  setLocations(adminLocations);
                }
                else
                  setLocations(Object.entries(snapshot.val()));
              } else {
              }
            });
        } else if (isLoggedIn == "owner" && users && users[0][1]) {
          let locationsIdsOfOwner = Object.keys(users[0][1]);
          let dummy = Object.entries(snapshot.val()).filter((loc) =>
            locationsIdsOfOwner.includes(loc[0])
          );
          setLocations(dummy);
        } else if (isLoggedIn == "user" && users && users[0][1]) {
          // setUserLocations(locations)
          let locationsIdsOfUser = Object.keys(users[0][1]);
          let dummy = Object.entries(snapshot.val()).filter((loc) =>
            locationsIdsOfUser.includes(loc[0])
          );
          //console.log("locationsOfUser", locationsIdsOfUser, locations, dummy)
          setLocations(dummy);
        }
      } else {
        //console.log("not")
      }

      onValue(dbRefDevice, (snapshot) => {
        if (snapshot.exists()) {
          setDevices(Object.entries(snapshot.val()));
        } else {
        }
      });
    });
  }, [users, isLoggedIn, userCred]);

  // function to categorize devices with same location
  function categorizeDevicesByLocation(objects) {
    let categorizedDevices = {};

    objects.forEach((obj) => {
      if (categorizedDevices[obj.location]) {
        categorizedDevices[obj.location].push(obj);
      } else {
        categorizedDevices[obj.location] = [obj];
      }
    });

    return categorizedDevices;
  }

  // To sink values in database and get values for card boxes
  useEffect(() => {
    const syncTotalProduction = async () => {
      if (locations.length > 0 && devicesForMin.length > 0) {
        let allDevicesObjects = [];
        devicesForMin.map((device) => {
          allDevicesObjects.push(device[1]);
        });
        let categorizedDevices = categorizeDevicesByLocation(allDevicesObjects);
        for (const [loc_id, categorizedDevicesArray] of Object.entries(
          categorizedDevices
        )) {
          let button1Counts = 0;
          let button2Counts = 0;
          let totalProduction = 0;

          categorizedDevicesArray.map((currentDevice) => {
            if (currentDevice.Button1) {
              button1Counts += parseInt(currentDevice.Button1.Count);
            }
            if (currentDevice.Button2) {
              button2Counts += 2 * parseInt(currentDevice.Button2.Count);
            }
          });

          totalProduction = button1Counts + button2Counts;
          const dbRefLoc = child(ref(db), `locations/` + loc_id);
          await update(dbRefLoc, {
            total_production: totalProduction,
          }).then(() => {
            // console.log("production added to "+ loc_id +' : '+ totalProduction + "total production synced")
          });
        }
      }
    };
    const syncSessionsAndsingleCups = () => {
      if (allLocations.length > 0) {
        allLocations.map(async (loc_) => {
          // sync sessions completed
          if ("sessions_completed" in loc_[1]) {
            if ("timestamps" in loc_[1].sessions_completed) {
              if (loc_[0] != 'E0:5A:1B:6C:6E:E8' || loc_[0] != '94:E6:86:02:87:28' || loc_[0] != 'E0:5A:1B:6C:9E:0C') {

                let sessionsCounter = 0;
                for (const [key, sessionTimestamp] of Object.entries(
                  loc_[1].sessions_completed.timestamps
                )) {
                  sessionsCounter++;
                }
                if (typeof loc_[1].location_id != "undefined") {
                  const dbRefSessionsCompleted = child(
                    ref(db),
                    "locations/" + loc_[1].location_id + "/sessions_completed/"
                  );
                  await update(dbRefSessionsCompleted, {
                    count: sessionsCounter,
                  }).then(() => {
                  });
                }
              }
            }
            let sessionsCompleted = loc_[1].sessions_completed.count;
            const dbRefSessionsCompleted = child(
              ref(db),
              "locations/" + loc_[1].location_id
            );
            // to sync sessions_total_revenue first will check is there any sell_price in location
            if ("sell_price" in loc_[1]) {
              let sellPrice = loc_[1].sell_price;

              let sessions_total_revenue = sellPrice * sessionsCompleted;
              // debugger
              await update(dbRefSessionsCompleted, {
                sessions_total_revenue: sessions_total_revenue,
              }).then(() => {
                console.log(
                  loc_[1].location_id +
                    " -> " +
                    sessions_total_revenue +
                    " sessions_total_revenue synced"
                );
              });
            }
            // sync laila_service_total_cost
            if ("laila_service_price" in loc_[1]) {
              if (loc_[1].business_model == "flat_fee") {
                let laila_service_total_cost =
                  sessionsCompleted * loc_[1].laila_service_price;
                laila_service_total_cost = parseFloat(
                  laila_service_total_cost.toFixed(2)
                );
                await update(dbRefSessionsCompleted, {
                  laila_service_total_cost: laila_service_total_cost,
                  laila_service_total_revenue: 0,
                }).then(() => {
                  console.log(
                    loc_[1].location_id +
                      " -> " +
                      laila_service_total_cost +
                      " laila_service_total_cost synced"
                  );
                });
              }

              // {sessions_completed x sell_price x (1-revenue_share)}
              else {
                let laila_service_total_cost =
                  sessionsCompleted *
                  loc_[1].laila_service_price *
                  (loc_[1].revenue_share / 100);
                laila_service_total_cost = parseFloat(
                  laila_service_total_cost.toFixed(2)
                );

                let laila_service_total_revenue =
                  sessionsCompleted *
                  loc_[1].laila_service_price *
                  (1 - loc_[1].revenue_share / 100);
                laila_service_total_revenue = parseFloat(
                  laila_service_total_revenue.toFixed(2)
                );
                await update(dbRefSessionsCompleted, {
                  laila_service_total_cost: laila_service_total_cost,
                  laila_service_total_revenue: laila_service_total_revenue,
                }).then(() => {
                  console.log(
                    loc_[1].location_id +
                      " -> " +
                      laila_service_total_cost +
                      " laila_service_total_cost synced"
                  );
                });
              }
            }
          }

          // sync single_cups_sold values to location
          // { total_production - sessions_completed }

          let singleCupsSold = 0;
          let sessionsComplete =
            typeof loc_[1]?.sessions_completed?.count !== "undefined"
              ? loc_[1].sessions_completed.count
              : 0;
          if ("total_production" in loc_[1]) {
            singleCupsSold = loc_[1].total_production - sessionsComplete;
            const locationDbRef = child(
              ref(db),
              "locations/" + loc_[1].location_id
            );
            await update(locationDbRef, {
              single_cups_sold: singleCupsSold,
            }).then(() => {
            });
          }

          // sync single_cups_total_revenue to location
          if ( "single_cups_sold" in loc_[1] && "single_cup_sell_price" in loc_[1] ) {
            let singleCupsTotalRevenue = loc_[1].single_cups_sold * loc_[1].single_cup_sell_price;
            const locationDbRef = child( ref(db),"locations/" + loc_[1].location_id );
            await update(locationDbRef, {
              single_cups_total_revenue: singleCupsTotalRevenue,
            }).then(() => {
            });
          }

          // sync coffee_total and delight_total under each location
          if("stocks" in loc_[1]){
            let locationCoffeeTotal = 0;
            let locationDelightTotal = 0;
            Object.entries(loc_[1].stocks).forEach((stockValue) => {
              locationCoffeeTotal += stockValue[1].coffee;
              locationDelightTotal += stockValue[1].delight;
            })
            console.log(loc_[1].location_id, locationCoffeeTotal)
            const locationStocksDbRef = child( ref(db),"locations/" + loc_[1].location_id);
            await update(locationStocksDbRef, {
              coffee_total : locationCoffeeTotal,
              delight_total: locationDelightTotal
            }).then(() => {
            });
          }
        });
      }
    };
    // to sync total_revenue to all locations
    const syncTotalRevenue = () => {
      if (allLocations.length > 0) {
        allLocations.map(async (loc_) => {
          let location_sessions_total_revenue =
            typeof loc_[1].sessions_total_revenue !== "undefined"
              ? loc_[1].sessions_total_revenue
              : 0;
          let location_single_cups_total_revenue =
            typeof loc_[1].single_cups_total_revenue !== "undefined"
              ? loc_[1].single_cups_total_revenue
              : 0;
          let total_revenue =
            location_sessions_total_revenue +
            location_single_cups_total_revenue;
          const locationDbRef = child(
            ref(db),
            "locations/" + loc_[1].location_id
          );
          await update(locationDbRef, {
            total_revenue,
          }).then(() => {
            console.log(loc_[1].location_id + " -> " + total_revenue);
          });
        });
      }
    };
    
    syncTotalProduction();
    syncSessionsAndsingleCups();
    syncTotalRevenue();
  }, [devicesForMin, locations]);

  useEffect(() => {
    // let locationsOfUser = Object.keys(users[0][1])

    if (locations && locations[0]) {
      tempCities = [];
      locations.forEach((location) => {
        if (
          !tempCities.includes(`${location[1].country}`) &&
          location[1].country != undefined
        ) {
          tempCities.push(location[1].country);
        } else {
        }

        setCities(tempCities);
        setAllData(locations);
      });
    }
    // other boxes data
    let total_production = 0;
    let total_cups_sold = 0;
    let revenue_of_sessions = 0;
    let revenue_of_cups = 0;
    let total_revenue_state = 0;
    locations.map((loc) => {
      // if any of the location has business model
      if ("laila_service_price" in loc[1]) {
        setHasLailaService(true);
      }

      // to set total production
      if ("total_production" in loc[1]) {
        total_production += loc[1].total_production;
      }
      if ("single_cups_sold" in loc[1]) {
        total_cups_sold += loc[1].single_cups_sold;
      }
      if ("sessions_total_revenue" in loc[1]) {
        revenue_of_sessions += loc[1].sessions_total_revenue;
      }
      if ("single_cups_total_revenue" in loc[1]) {
        revenue_of_cups += loc[1].single_cups_total_revenue;
      }
      if ("total_revenue" in loc[1]) {
        total_revenue_state += loc[1].total_revenue;
      }
    });
  }, [locations]);

  useEffect(() => {
    if (selectedCities.length > 0) {
      let tempLocals = [];
      
      // console.warn("selectedCities", selectedCities, allData)
      selectedCities.forEach((selectedCity) => {
        allData.forEach((data) => {
          if (selectedCity.value == `${data[1].country}`) {
            tempLocals.push(data[1].city);
          } else {
          }
        });
      });
      let uniqeLocal = [];
      // // console.warn(tempLocals)
      tempLocals.forEach((local) => {
        if (!uniqeLocal.includes(local)) {
          uniqeLocal.push(local);
          // // console.warn(uniqeLocal)
        }
      });
      setLocals(uniqeLocal);
    } else {
      setSelectedLocals([]);
      setLocals([]);
    }
  }, [selectedCities, allData]);

  useEffect(() => {
    if (selectedLocals.length > 0) {
      let tempLocals = [];

      selectedLocals.forEach((selectedLocal) => {
        allData.forEach((data) => {
          if (selectedLocal.value == `${data[1].city}`) {
            tempLocals.push({
              id: data[1].location_id,
              name: data[1].location_name,
            });
          } else {
            // // console.log(data[1].country, selectedCities)
          }
        });
      });

      setShops(tempLocals);
    } else {
      setShops([]);
      setSelectedShop([]);
    }
  }, [selectedLocals, allData]);

  const [x, setX] = useState([]);

  const [totalButton1State, setTotalButton1State] = useState(0);
  const [totalButton2State, setTotalButton2State] = useState(0);
  const [profit, setProfit] = useState();
  const [selectedDatasState, setSelectedDataState] = useState();
  const [selectedDevicesState, setSelectedDevicesState] = useState();
  const [selectedRevenueShare, setSelectedRevenueShare] = useState();
  const [selectedTotalRevenue, setSelectedTotalRevenue] = useState();
  const [selectedTotalProfit, setSelectedTotalProfit] = useState();

  useEffect(() => {
    // // console.log(selectedShop)
    let selectedShopsStocks = [];
    let shopsStocks = [];
    // console.error(selectedShop)

    const calcStocks = () => {
      // console.log(allData)
      let revenue_share = 0;
      let total_revenue = 0;
      let total_profit = 0;
      selectedShop.forEach((shop) => {
        let stocks = allData.filter(
          (data) => data[1].location_id == shop.value
        );
        // // console.warn("stocks", stocks)
        let coffeeStock = 0;
        let delightStock = 0;
        let sugarStock = 0;
        // // console.warn(stocks)
        if (stocks[0][1].total_revenue) {
          total_revenue += stocks[0][1].total_revenue;
        }

        if (stocks[0][1].revenue_share) {
          revenue_share += stocks[0][1].revenue_share;
        }

        total_profit += total_revenue * ((100 - revenue_share) / 100);

        setSelectedTotalRevenue(total_revenue);
        setSelectedRevenueShare(revenue_share);
        setSelectedTotalProfit(total_profit);
        if (stocks.length && stocks[0].length > 0 && stocks[0][1].stocks) {
          Object.entries(stocks[0][1].stocks).forEach((stock) => {
            // // console.log(stock[1].coffee)
            coffeeStock += stock[1].coffee;
            delightStock += stock[1].delight;
            sugarStock += stock[1].sugar;

            // // console.warn(coffeeStock)
          });
          shopsStocks.push({
            id: shop.value,
            stocks: {
              coffe: coffeeStock,
              delight: delightStock,
              sugar: sugarStock,
            },
          });
          // // console.warn(shopsStocks)
          selectedShopsStocks.push(shopsStocks);
        }
      });
    };

    const calcTotalRevenue = () => {
      let selectedDatasDevicesListString = [];

      selectedShop.forEach((shop) => {
        let selectedDatas = allData.filter(
          (data) => data[1].location_id == shop.value
        );
          
        if (selectedDatas[0] && selectedDatas[0][1].devices) {
          // console.warn("selectedDatas", selectedDatas, Object.keys(selectedDatas[0][1].devices))
          selectedDatasDevicesListString.push(
            Object.keys(selectedDatas[0][1].devices)
          );
        } else {
          setTotalButton1State(0);
          setTotalButton2State(0);
          return;
        }
      });
      setSelectedDevicesState(selectedDatasDevicesListString);

      // console.warn(Object.values(selectedDatasDevicesListString))
      let totalButton1 = 0;
      let totalButton2 = 0;
      if (Object.values(selectedDatasDevicesListString).length > 0) {
        // console.warn("devices", devices)
        let selectedDevicesObject = [];
        devices.forEach((device) => {
          // console.log("224", selectedDatasDevicesListString)
          let selectedDevicesArr = [];
          selectedDatasDevicesListString.forEach((item) => {
            item.forEach((inItem) => {
              selectedDevicesArr.push(inItem);
            });
          });
          // console.log("helloooooo", selectedDevicesArr, device[0])

          selectedDevicesArr.forEach((item) => {
            if (item.includes(`${device[0]}`)) {
              //console.log("innnnnnnn")
              // console.warn(Object.keys(device[1].Button1.Timestamp).length)
              // console.warn("device", device[0])
              // totalButton1 = totalButton1 + (Object.keys(device[1].Button1.Timestamp).length)
              // totalButton2 = totalButton2 + (Object.keys(device[1].Button2.Timestamp).length)

              if (device[1].Button1 && !device[1].Button2) {
                selectedDevicesObject.push({
                  id: device[0],
                  btn1: Object.keys(device[1].Button1.Timestamp).length,
                  btn2: 0,
                });
              } else if (device[1].Button2 && !device[1].Button1)
                selectedDevicesObject.push({
                  id: device[0],
                  btn1: 0,
                  btn2: Object.keys(device[1].Button2.Timestamp).length,
                });
              else if (!device[1].Button2 && !device[1].Button1)
                selectedDevicesObject.push({ id: device[0], btn1: 0, btn2: 0 });
              else
                selectedDevicesObject.push({
                  id: device[0],
                  btn1: Object.keys(device[1].Button1.Timestamp).length,
                  btn2: Object.keys(device[1].Button2.Timestamp).length,
                });
              // console.warn("totalsState", totalButton1State, totalButton2State, Object.keys(device[1].Button1.Timestamp).length)
              // console.warn(totalButton1, totalButton2, "totals")
            }
          });
        });
        //console.warn("selectedDevicesObject", selectedDevicesObject)
        let bt1Count = 0;
        let btn2Count = 0;
        selectedDevicesObject.forEach((object) => {
          //console.warn(object)
          bt1Count += object.btn1;
          btn2Count += object.btn2;
        });

        //console.log(btn2Count, bt1Count)
        setTotalButton1State(bt1Count);
        setTotalButton2State(2 * btn2Count);
        // setTotalButton1State(totalButton1)
        // setTotalButton2State(totalButton2)
      }

      // console.warn("total2", totalButton1State)
      let sumOfSale = 0;
      let tempAllSelectedDatas = [];
      selectedShop.forEach((shop) => {
        let selectedDatas = allData.filter(
          (data) => data[1].location_id == shop.value
        );
        tempAllSelectedDatas.push(selectedDatas);
        // console.warn(selectedDatas)
        let sellPriceOfSelectedShop = 0;
        if (selectedDatas && selectedDatas[0] && selectedDatas[0][1]) {
          sellPriceOfSelectedShop = selectedDatas[0][1].sell_price;
        }
        setSelectedDataState(tempAllSelectedDatas);
        sumOfSale +=
          sellPriceOfSelectedShop * (totalButton1State + totalButton2State);
        // console.warn("sumOfSale", sumOfSale)
        // console.log(sumOfSale, selectedShop, selectedDatas, sellPriceOfSelectedShop, totalButton1State, totalButton2State)
      });
      setProfit(sumOfSale);
    };

    const calcBoxValues = () => {
      // to get values for boxes
      let total_laila_sessions = 0;
      let total_laila_revenue = 0;
      let total_laila_cost = 0;
      let total_laila_net_income = 0;
      // other boxes data
      let total_production = 0;
      let total_cups_sold = 0;
      let revenue_of_sessions = 0;
      let revenue_of_cups = 0;
      let total_revenue_state = 0;
      setHasLailaService((prevState)=> prevState = false)
      selectedShop.forEach((shop) => {
        let loc = allData.filter((data) => data[1].location_id == shop.value);
        
        //-----------calculate all box values here---------------
        // if any of the location has business model
        if (loc[0] && loc[0][1].laila_service_price) {
          // debugger
          setHasLailaService(true);
        }
        

        if(loc[0] && loc[0][1].total_production){
          total_production += loc[0][1].total_production;
        }

        if(loc[0] && loc[0][1].single_cups_sold){
          total_cups_sold += loc[0][1].single_cups_sold;
        }
        
        if(loc[0] && loc[0][1].sessions_total_revenue){
          revenue_of_sessions += loc[0][1].sessions_total_revenue;
        }
        
        if(loc[0] && loc[0][1].single_cups_total_revenue){
          revenue_of_cups += loc[0][1].single_cups_total_revenue;
        }
        
        if(loc[0] && loc[0][1].total_revenue){
          total_revenue_state += loc[0][1].total_revenue;
        }
        
        if(loc[0] && loc[0][1].sessions_completed){
          if (typeof loc[0][1].sessions_completed?.count !== "undefined") {
            total_laila_sessions += loc[0][1].sessions_completed?.count;
          }
        }

        if (loc[0] && loc[0][1].business_model == "revenue_share") {
          let totalRevenueShare = (typeof loc[0][1].laila_service_total_cost =="undefined" ? 0 : loc[0][1].laila_service_total_cost) + (typeof loc[0][1].laila_service_total_revenue == "undefined" ? 0 : loc[0][1].laila_service_total_revenue); 
          // debugger
          total_laila_revenue += totalRevenueShare;
          total_laila_net_income += typeof loc[0][1].laila_service_total_revenue  == "undefined" ? 0 : loc[0][1].laila_service_total_revenue;
        }

        if (loc[0] && loc[0][1].laila_service_total_cost) {
          total_laila_cost += loc[0][1].laila_service_total_cost;
        }
      });
      if(selectedShop.length == 1 ){
        let filteredOneShop = allData.filter((data) => data[1].location_id == selectedShop[0].value);
        console.log(filteredOneShop[0][1].currency)
        if(filteredOneShop[0][1].currency == 'EURO'){
          setCurrency("€");
        }
        else if(filteredOneShop[0][1].currency == 'GBP'){
          setCurrency("£");
        }
        else if(filteredOneShop[0][1].currency == 'TL'){
          setCurrency("₺");
        }
        else if(filteredOneShop[0][1].currency == 'USD'){
          setCurrency("$");
        }
        else
        setCurrency("€");
        
      }
      setTotalLailaSessionsState(total_laila_sessions);
      setTotalLailaRevenueState(total_laila_revenue);
      setTotalLailaCostState(total_laila_cost);
      setLailaNetIncomeState(total_laila_net_income);
      setTotalProductionState(total_production);
      setCupsSoldState(total_cups_sold);
      setRevenueSessionsState(revenue_of_sessions);
      setRevenueOfCupsState(revenue_of_cups);
    }
    // // console.warn(shopsStocks)
    setX(selectedShopsStocks);
    if (allData.length > 0) {
      calcBoxValues();
      calcStocks();
      calcTotalRevenue();
      // // console.log("selectedShopsStocks", selectedShopsStocks)
    }
  }, [selectedShop, devices, allData, totalButton1State, totalButton2State]);

  function addSubUser() {
    if (subUserNameInput.length == 0 || subUserEmailInput.length == 0) {
      setErrorModalMessage("Email and Name Surname cannot be empty.");
      setErrorModal(true);
      return;
    }
    console.log(selectedRole);

    const date = new Date();
    createUserWithEmailAndPassword(auth, subUserEmailInput, "123456")
      .then((userCredential) => {
        let selectedLocationForNewUser = {};
        sendPasswordResetEmail(auth, subUserEmailInput)
          .then(() => {
            // console.log('userCredential.user.uid', userCredential.user.uid)
            if (isLoggedIn == "admin" && selectedRole != null) {
              if (selectedRole == "Admin") {
                const dbRefAdmins = child(
                  ref(db),
                  `admins/` + `${userCredential.user.uid}`
                );
                set(dbRefAdmins, true);
              }
              if (selectedRole == "Technician") {
                const dbRefTechnicians = child(
                  ref(db),
                  `technicians/` + `${userCredential.user.uid}`
                );
                set(dbRefTechnicians, true);
              }
              const dbRefUserCreds = child(
                ref(db),
                `usersCreds/` + `${userCredential.user.uid}`
              );
              set(dbRefUserCreds, {
                email: `${userCredential.user.email}`,
                uid: `${userCredential.user.uid}`,
                registerTime: date.getTime(),
                Role: selectedRole,
                NameAndSurname: subUserNameInput,
                Country: userCountry,
              });
            } else {
              Object.keys(selectedShop).forEach((shop) => {
                selectedLocationForNewUser[selectedShop[shop].value] = true;
                const dbRefLocationsUsers = child(
                  ref(db),
                  `locations/` +
                    `${selectedShop[shop].value}/` +
                    "users/" +
                    `${userCredential.user.uid}`
                );
                set(dbRefLocationsUsers, {
                  valid: true,
                  Role: "Subuser",
                  NameAndSurName: subUserNameInput,
                });
              });
              const dbRefUsers = child(
                ref(db),
                `users/` + `${userCredential.user.uid}`
              );
              set(dbRefUsers, {
                valid: true,
                locations: selectedLocationForNewUser,
              });
              const dbRefUserCreds = child(
                ref(db),
                `usersCreds/` + `${userCredential.user.uid}`
              );
              set(dbRefUserCreds, {
                email: `${userCredential.user.email}`,
                uid: `${userCredential.user.uid}`,
                registerTime: date.getTime(),
                Role: "Subuser",
              });
            }
            setSuccessModal(true);
            setSubUserEmailInput("");
            setSubUserNameInput("");
          })
          .catch((error) => {
            console.log(error);
            // setErrorModal(true)
          });
      })
      .catch((e) => {
        setErrorModalMessage("Email in use.");
        setErrorModal(true);
      });
  }

  const [inComplete, setInComplete] = useState(0);
  const [allInComplete, setAllInComplete] = useState(0);

  useEffect(() => {
    let selectedInComplete = [];
    selectedShop.forEach((shop) => {
      allData.forEach((data) => {
        if (data[1].location_id == shop.value && data[1].incomplete_ids) {
          if (Object.values(data[1].incomplete_ids).length > 1) {
            Object.values(data[1].incomplete_ids).forEach((item) => {
              selectedInComplete.push(item);
            });
          } else {
            selectedInComplete.push(Object.values(data[1].incomplete_ids)[0]);
          }
        }
      });
    });
    selectedInComplete.forEach((item) => console.error(item.timestamp));

    let allInComplete = [];
    let finalIncomplete = [];
    selectedInComplete.forEach((item) => {
      if (
        item.timestamp >= startDate.getTime() &&
        item.timestamp <= endDate.getTime()
      ) {
        finalIncomplete.push(item.timestamp);
      } else console.error(item.timestamp, startDate.getTime());

      allInComplete.push(item.timestamp);
    });
    console.error(selectedInComplete, finalIncomplete);

    setAllInComplete(allInComplete.length);
    setInComplete(finalIncomplete.length);
  }, [selectedShop, startDate, endDate]);

  const [totalStocks, setTotalStocks] = useState();
  let totalCoffe = 0;
  let totalDelight = 0;
  let totalSugar = 0;
  useEffect(() => {
    if (x.length > 0) {
      // // console.log("x", x[0][0])

      x[0].forEach((a) => {
        totalCoffe = totalCoffe + a.stocks.coffe;
        totalDelight = totalDelight + a.stocks.delight;
        // // console.log(a.stocks)
      });
      setTotalStocks([totalCoffe, totalDelight]);
    }
  }, [x]);

  useEffect(() => {
    // // console.log("*************************************************", allData)
    if (allData.length > 0) {
      // // console.warn(allData)
    } else {
      // // console.warn(allData)
    }
  }, [allData]);

  useEffect(() => {
    // console.warn("devices", devices)
  }, [devices]);

  // SIGN OUT ==============================================================================================

  const signOut = () => {
    setIsLoggedIn("false");
    sessionStorage.clear();
  };

  const showChartsBoxes = () => {
    window.location.reload();
    // const subUserPage = document.getElementById("subUserPage");
    // const dataBoxWrapper = document.getElementById("counters");
    // const chartWrapperWrapper = document.getElementById("chartWrapperWrapper");
    // if (subUserPage.style.display === "block") {
    //   subUserPage.style.display = "none";
    //   dataBoxWrapper.style.display = "flex";
    //   chartWrapperWrapper.style.display = "flex";
    // }
  };
  const openAddSubUserPage = () => {
    const subUserPage = document.getElementById("subUserPage");
    const dataBoxWrapper = document.getElementById("counters");
    const chartWrapperWrapper = document.getElementById("chartWrapperWrapper");
    if (subUserPage.style.display === "block") {
      subUserPage.style.display = "none";
      dataBoxWrapper.style.display = "flex";
      chartWrapperWrapper.style.display = "flex";
    } else {
      subUserPage.style.display = "block";
      dataBoxWrapper.style.display = "none";
      chartWrapperWrapper.style.display = "none";
    }
  };

  function formatNumber(num) {
    if (num >= 1000000) {
      return (num / 1000000).toFixed(1) + "M";
    } else if (num >= 10000) {
      return (num / 1000).toFixed(1) + "K";
    } else {
      return num;
    }
  }
  function formatNumberWithCommas(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  const [subUserNameInput, setSubUserNameInput] = useState("");
  const [subUserEmailInput, setSubUserEmailInput] = useState("");

  const handleSubUserEmailInput = (event) => {
    setSubUserEmailInput(event.target.value);
  };

  const handleSubUserNameInput = (event) => {
    setSubUserNameInput(event.target.value);
  };

  function handleToggleUserClick(userId, locationId, isValid) {
    const dbRefLocationsUsersToggle = child(
      ref(db),
      `locations/` + `${locationId}/` + "users/" + `${userId}/` + "valid"
    );
    set(dbRefLocationsUsersToggle, !isValid);

    // console.log(`User ID: ${userId}, Location ID: ${locationId}`);
  }
  const handleAddSubUser = () => {
    let options = [{ value: "all", label: `All` }];
    shops.forEach((shop) => {
      options.push({ value: `${shop.id}`, label: `${shop.name}` });
    });
    setSelectedShop(options.slice(1, options.length));
  };
  function getSubUsersAndNames() {
    const selectedShopIds = selectedShop.map((obj) => obj.value);
    const locationAndSubusers = [];
    locations.forEach((loc) => {
      if (selectedShopIds.includes(loc[1].location_id)) {
        let users = loc[1].users;

        let userObj = Object.keys(users).map((user) => ({
          id: user,
          names: users[user].NameAndSurName,
          isValid: users[user].valid,
          Role: users[user].Role,
        }));
        // console.log(userObj)
        locationAndSubusers.push({
          locationId: loc[0],
          locationName: loc[1].location_name,
          users: userObj,
        });
      }
    });
    return (
      <table border="1">
        <thead>
          <th>UID</th>
          <th>Name and surname</th>
          <th>Location name</th>
          <th>Role</th>
          <th>Delete user</th>
        </thead>
        <tbody>
          {locationAndSubusers.map((location, index) =>
            location.users.map((user, index) => (
              <tr>
                {/* UID */}
                <td>{user.id}</td>
                {/* Name and surname */}
                <td>
                  {user.names ? (
                    <div
                      className={`subUserName ${
                        user.isValid ? "fillGreen" : "fillGrey"
                      }`}
                    >
                      {user.names}
                    </div>
                  ) : (
                    <div class="fillGreen">ADMIN</div>
                  )}
                </td>
                {/* Location name */}
                <td>{location.locationName}</td>
                {/*Role  */}
                <td>
                  {user.names &&
                    // user.isValid && (<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><path d="M309 106c11.4-7 19-19.7 19-34c0-22.1-17.9-40-40-40s-40 17.9-40 40c0 14.4 7.6 27 19 34L209.7 220.6c-9.1 18.2-32.7 23.4-48.6 10.7L72 160c5-6.7 8-15 8-24c0-22.1-17.9-40-40-40S0 113.9 0 136s17.9 40 40 40c.2 0 .5 0 .7 0L86.4 427.4c5.5 30.4 32 52.6 63 52.6H426.6c30.9 0 57.4-22.1 63-52.6L535.3 176c.2 0 .5 0 .7 0c22.1 0 40-17.9 40-40s-17.9-40-40-40s-40 17.9-40 40c0 9 3 17.3 8 24l-89.1 71.3c-15.9 12.7-39.5 7.5-48.6-10.7L309 106z"/></svg>)
                    user.Role}
                </td>
                <td>
                  {user.names ? (
                    <a
                      href="#"
                      class="test1"
                      onClick={() =>
                        handleToggleUserClick(
                          user.id,
                          location.locationId,
                          user.isValid
                        )
                      }
                    >
                      <span>
                        <svg
                          class="fillRed"
                          xmlns="http://www.w3.org/2000/svg"
                          height="1em"
                          viewBox="0 0 448 512"
                        >
                          <path d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0H284.2c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32h96l7.2-14.3zM32 128H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zm96 64c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16z" />
                        </svg>
                      </span>
                    </a>
                  ) : (
                    ""
                  )}
                  {user.names ? (
                    <a
                      href="#"
                      onClick={() =>
                        handleToggleUserClick(
                          user.id,
                          location.locationId,
                          user.isValid
                        )
                      }
                      class="test1"
                    >
                      {user.isValid ? (
                        <span style={{ marginLeft: "15px" }}>
                          <svg
                            class="fillGrey"
                            xmlns="http://www.w3.org/2000/svg"
                            height="1em"
                            viewBox="0 0 640 512"
                          >
                            <path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L353.3 251.6C407.9 237 448 187.2 448 128C448 57.3 390.7 0 320 0C250.2 0 193.5 55.8 192 125.2L38.8 5.1zM264.3 304.3C170.5 309.4 96 387.2 96 482.3c0 16.4 13.3 29.7 29.7 29.7H514.3c3.9 0 7.6-.7 11-2.1l-261-205.6z" />
                          </svg>
                        </span>
                      ) : (
                        <span style={{ marginLeft: "15px" }}>
                          <svg
                            class="fillGreen"
                            xmlns="http://www.w3.org/2000/svg"
                            height="1em"
                            viewBox="0 0 640 512"
                          >
                            <path d="M96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3zM625 177L497 305c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L591 143c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
                          </svg>
                        </span>
                      )}
                    </a>
                  ) : (
                    ""
                  )}
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>

      // <div class="data--box">
      //   {locationAndSubusers.map((location, index) => (
      //     <div class="data-box" key={index}>
      //       <h2>{location.locationName}</h2>
      //       <ul>
      //         {location.users.map((user, index) => (

      //           <li key={index} class="subUserNameLine">

      //             {user.names ? (
      //               <div className={`subUserName ${user.isValid ? 'fillGreen' : 'fillGrey'}`} >{user.names}</div>
      //             ) : <div class='fillGreen'>ADMIN</div>}
      //             {user.names ? (
      //               <div class="subUserName--a">
      //                 <a
      //                   href="#"
      //                   onClick={() =>
      //                     handleToggleUserClick(user.id, location.locationId, user.isValid)
      //                   }
      //                   class="test1">
      //                   {user.isValid ? <span><svg class='fillGrey' xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512"><path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L353.3 251.6C407.9 237 448 187.2 448 128C448 57.3 390.7 0 320 0C250.2 0 193.5 55.8 192 125.2L38.8 5.1zM264.3 304.3C170.5 309.4 96 387.2 96 482.3c0 16.4 13.3 29.7 29.7 29.7H514.3c3.9 0 7.6-.7 11-2.1l-261-205.6z" /></svg></span> : <span><svg class='fillGreen' xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512"><path d="M96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3zM625 177L497 305c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L591 143c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" /></svg></span>}
      //                 </a>
      //                 <a
      //                   href="#"
      //                   class="test1"
      //                   onClick={() =>
      //                     handleToggleUserClick(user.id, location.locationId, user.isValid)
      //                   }
      //                 >
      //                   <span><svg class='fillRed' xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0H284.2c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32h96l7.2-14.3zM32 128H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zm96 64c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16z" /></svg></span>
      //                 </a>
      //               </div>
      //             ) : ''}
      //           </li>
      //         ))}
      //       </ul>
      //     </div>
      //   ))}
      // </div>
    );
  }
  const valueChanged = (value) => {
    setSelectedRole(value.value);
  };
  const countryOptionChange = (value) => {
    setUserCountry(value.value);
  };
  return (
    <div className="App">
      {/* <CallLocationsData setCities={setCities} allData={allData} setAllData={setAllData} />
      <CallDevicesData setDevices={setDevices} /> */}
      <div
        id="myModal"
        className={errorModal ? "modal fade show" : "modal fade"}
      >
        <div className="modal-dialog modal-confirm">
          <div className="modal-content">
            <div className="modal-header justify-content-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="48"
                viewBox="0 96 960 960"
                width="48"
              >
                <path d="m330 768 150-150 150 150 42-42-150-150 150-150-42-42-150 150-150-150-42 42 150 150-150 150 42 42Zm150 208q-82 0-155-31.5t-127.5-86Q143 804 111.5 731T80 576q0-83 31.5-156t86-127Q252 239 325 207.5T480 176q83 0 156 31.5T763 293q54 54 85.5 127T880 576q0 82-31.5 155T763 858.5q-54 54.5-127 86T480 976Zm0-60q142 0 241-99.5T820 576q0-142-99-241t-241-99q-141 0-240.5 99T140 576q0 141 99.5 240.5T480 916Zm0-340Z" />
              </svg>
              <button
                type="button"
                className="close"
                onClick={() => setErrorModal(false)}
                data-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button>
            </div>
            <div className="modal-body text-center">
              <h4>Error Occured!</h4>
              <p>{errorModalMessage}</p>
              <button
                className="btn btn-success"
                onClick={() => setErrorModal(false)}
                data-dismiss="modal"
              >
                Try Again
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        id="myModal"
        className={
          successModal ? "modal success fade show" : "modal success fade"
        }
      >
        <div className="modal-dialog modal-confirm">
          <div className="modal-content">
            <div className="modal-header justify-content-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="48"
                viewBox="0 96 960 960"
                width="48"
              >
                <path d="M378 810 154 586l43-43 181 181 384-384 43 43-427 427Z" />
              </svg>
              <button
                type="button"
                className="close"
                onClick={() => setSuccessModal(false)}
                data-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button>
            </div>
            <div className="modal-body text-center">
              <h4>Great!</h4>
              <p>New profile added successfully.</p>
              <button
                className="btn btn-success"
                onClick={() => setSuccessModal(false)}
                data-dismiss="modal"
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* <div id="modal" className={`error ${errorModal}`}>
        <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 96 960 960" width="40"><path d="M479.988 776q15.012 0 25.179-10.155 10.166-10.155 10.166-25.167 0-15.011-10.155-25.178-10.155-10.166-25.166-10.166-15.012 0-25.179 10.155-10.166 10.154-10.166 25.166t10.155 25.178Q464.977 776 479.988 776Zm-31.321-155.333h66.666V372h-66.666v248.667ZM480.177 976q-82.822 0-155.666-31.5t-127.178-85.833Q143 804.333 111.5 731.44 80 658.546 80 575.667q0-82.88 31.5-155.773Q143 347 197.333 293q54.334-54 127.227-85.5Q397.454 176 480.333 176q82.88 0 155.773 31.5Q709 239 763 293t85.5 127Q880 493 880 575.823q0 82.822-31.5 155.666T763 858.544q-54 54.21-127 85.833Q563 976 480.177 976Zm.156-66.666q139 0 236.001-97.334 97-97.333 97-236.333t-96.875-236.001q-96.876-97-236.459-97-138.667 0-236 96.875Q146.666 436.417 146.666 576q0 138.667 97.334 236 97.333 97.334 236.333 97.334ZM480 576Z" /></svg>
        <p>Start date bigger than end date!</p>
        <button onClick={() => setErrorModal(false)}>Okey</button>
      </div> */}
      <div id="page-wrapper">
        <header>
          <div onClick={showChartsBoxes} className="brand-logo">
            <img src={logo}></img>
          </div>
          {(isLoggedIn == "admin" || isLoggedIn == "owner") && (
            <div
              onClick={openAddSubUserPage}
              className="header-boxes addSubUserPageButton"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 640 512"
              >
                <path d="M96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3zM504 312V248H440c-13.3 0-24-10.7-24-24s10.7-24 24-24h64V136c0-13.3 10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H552v64c0 13.3-10.7 24-24 24s-24-10.7-24-24z" />
              </svg>
              {/* <img onClick={signOut} src={logOutRed}></img> */}
            </div>
          )}

          <div onClick={signOut} className="header-boxes logout-button">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z" />
            </svg>
            {/* <img onClick={signOut} src={logOutRed}></img> */}
          </div>
        </header>

        <div>
          <div className="selection-container"></div>
          <div id="data-container">
            <div className="admin-labels-wrapper">
              <div className="label--selector">
                <label>Country</label>
                <SelectorInputCity
                  users={users}
                  isLoggedIn={isLoggedIn}
                  cities={cities}
                  setCities={setCities}
                  locations={locations}
                  setSelectedCities={setSelectedCities}
                  selectedCities={selectedCities}
                />
              </div>
              <div className="label--selector">
                <label>City</label>
                <SelectorInputLocal
                  users={users}
                  isLoggedIn={isLoggedIn}
                  locals={locals}
                  setSelectedLocals={setSelectedLocals}
                />
              </div>
              <div className="label--selector">
                <label>Venue</label>
                <SelectorInputShop
                  users={users}
                  isLoggedIn={isLoggedIn}
                  shops={shops}
                  setSelectedShop={setSelectedShop}
                  selectedShop={selectedShop}
                />
              </div>
              <div className="custom--selector-wrapper">
                <div className="label--selector">
                  <label>Data Range</label>
                  <DataRange
                    minTime={minTime}
                    dataRange={dataRange}
                    setDataRange={setDataRange}
                    setIsCustomRange={setIsCustomRange}
                  />
                </div>
                {isCustomRange ? (
                  <div id="datePickerWrapper">
                    <div>
                      <label>Start Date</label>
                      <div id="bar"></div>
                      <DatePicker
                        className="datePicker"
                        id="startDatePicker"
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                      />
                    </div>
                    <div>
                      <label>End Date</label>
                      <div id="bar"></div>
                      <DatePicker
                        className="datePicker"
                        id="endDatePicker"
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                      />
                    </div>
                  </div>
                ) : null}
              </div>
            </div>

            <div id="subUserPage">
              <div id="subUserPageInputWrapper">
                <div class="input--field">
                  <div class="label--addSubUserInput">Email</div>
                  <input
                    type="text"
                    value={subUserEmailInput}
                    className="addSubUserInput"
                    onChange={handleSubUserEmailInput}
                  />
                </div>
                <div class="input--field">
                  <div class="label--addSubUserInput">Name Surname</div>
                  <input
                    type="text"
                    value={subUserNameInput}
                    className="addSubUserInput"
                    onChange={handleSubUserNameInput}
                  />
                </div>
                {isLoggedIn == "admin" && (
                  <>
                    <div class="input--field">
                      <div class="label--addSubUserInput custom-controls">
                        Role
                      </div>
                      <Select
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            minHeight: "44px",
                          }),
                        }}
                        id="job-selector"
                        options={[
                          { value: "Admin", label: "Admin" },
                          { value: "Technician", label: "Technician" },
                        ]}
                        onChange={valueChanged}
                      />
                    </div>
                    <div class="input--field">
                      <div class="label--addSubUserInput custom-controls">
                        Country
                      </div>
                      <Select
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            minHeight: "44px",
                          }),
                        }}
                        id="job-selector"
                        options={countryOption}
                        onChange={countryOptionChange}
                      />
                    </div>
                  </>
                )}

                <button onClick={addSubUser} class="btn" type="addSubUsers">
                  Add User
                </button>
              </div>
              {/* <div id="subUserPageInputWrapper">
                <input type='text' value={subUserNameInput} className="addSubUserInput searchBar"  placeholder='Search User By UID (User Id from Firebase Authentication)'/>
              </div> */}
              <div className="usersContainer">{getSubUsersAndNames()}</div>
            </div>

            <div className="admin-labels-wrapper" id="counters">
              {/* When  laila service is active i.e. business model have some value then laila boxes else other boxes to show*/}

              {isLoggedIn == "owner" &&
              locations.length > 0 &&
              "business_model" in locations[0][1] ? (
                <>
                  <CardBox
                    title="Total Laila sessions"
                    svg={svgs.sessionsCompleted}
                    boxValue={totalLailaSessionsState}
                    boxSubValue={0}
                  />

                  {/* show only when business_model == "revenue_share" */}
                  {locations[0][1].business_model == "revenue_share" && (
                    <CardBox
                      title="Total Laila revenue"
                      svg={svgs.revenueOfCups}
                      boxValue={totalLailaRevenueState}
                      boxSubValue={0}
                    />
                  )}

                  <CardBox
                    title="Total Laila cost"
                    svg={svgs.totalLailaCost}
                    boxValue={totalLailaCostState}
                    boxSubValue={0}
                  />

                  {/* show only when business_model == "revenue_share" */}
                  {locations[0][1].business_model == "revenue_share" && (
                    <CardBox
                      title="Total Laila net income"
                      svg={svgs.lailaNetIncome}
                      boxValue={lailaNetIncomeState}
                      boxSubValue={0}
                    />
                  )}
                </>
                // If It's not a location owner then it will be admin and it will get all boxes
              ) : (
                <>
                  <CardBox
                    title="Total cups produced"
                    svg={svgs.totalProductionSvg}
                    boxValue={formatNumberWithCommas(Math.round(totalProductionState))}
                    boxSubValue={0}
                  />

                  <CardBox
                    title="Fortune sessions served"
                    svg={svgs.sessionsCompleted}
                    boxValue={formatNumberWithCommas( Math.round(totalLailaSessionsState) )}
                    boxSubValue={0}
                  />

                  <CardBox
                    title="Solo cups sold"
                    svg={svgs.cupsSold}
                    boxValue={formatNumberWithCommas( Math.round(cupsSoldState) )}
                    boxSubValue={0}
                  />

                  <CardBox
                    title={`Revenue of fortune sessions ${currency}`}
                    svg={svgs.revenueOfSessions}
                    boxValue={formatNumberWithCommas( Math.round(revenueSessionsState) )}
                    boxSubValue={0}
                  />

                  <CardBox
                    title={`Revenue of solo cups ${currency}`}
                    svg={svgs.revenueOfCups}
                    boxValue={formatNumberWithCommas( Math.round(revenueOfCupsState) )}
                    boxSubValue={0}
                  />

                  <CardBox
                    title={`Total revenue generated ${currency}` }
                    svg={svgs.totalRevenue}
                    boxValue={
                      totalStocks
                        ? `${formatNumber( formatNumberWithCommas( Math.round(selectedTotalRevenue) ) )}`
                        : null
                    }
                    boxSubValue={0}
                  />

                  <CardBox
                    title="Incomplete sessions"
                    svg={svgs.incompleteSessions}
                    boxValue={selectedCities.length ? formatNumberWithCommas( Math.round(inComplete) ) : 0}
                    boxSubValue={
                      isLoggedIn != "user"
                        ? "Undefined " + allInComplete + " sessions"
                        : null
                    }
                  />

                  <CardBox
                    title="Total cups/Coffee stock"
                    svg={svgs.totalCupsCoffeeStock}
                    boxValue={
                      x.length
                        ? totalStocks &&
                          formatNumber(
                            formatNumberWithCommas( 
                              Math.round(
                                (totalStocks[0] / 8) - totalProductionState
                              )
                            )
                          )
                        : 0
                    }
                    boxSubValue={
                      x.length
                        ? totalStocks &&
                          // Math.round(
                            (totalStocks[0] - (totalProductionState * 8) ) / 1000 
                          // ) 
                          +
                            " kg"
                        : 0
                    }
                  />
                  {hasLailaService && (<CardBox
                    title={`Total Laila sessions ${currency}`}  
                    svg={svgs.sessionsCompleted}
                    boxValue={formatNumberWithCommas( Math.round(totalLailaSessionsState) )}
                    boxSubValue={0}
                  />)}
                  <CardBox
                    title="Total Laila revenue"
                    svg={svgs.revenueOfCups}
                    boxValue={formatNumberWithCommas( Math.round(totalLailaRevenueState) )}
                    boxSubValue={0}
                  />
                  <CardBox
                    title="Total Laila cost"
                    svg={svgs.totalLailaCost}
                    boxValue={formatNumberWithCommas( Math.round(totalLailaCostState) )}
                    boxSubValue={0}
                  />
                  <CardBox
                    title="Total Laila net income"
                    svg={svgs.lailaNetIncome}
                    boxValue={formatNumberWithCommas( Math.round(lailaNetIncomeState) )}
                    boxSubValue={0}
                  />
                </>
              )}
            </div>
            {/* <div id="chartWrapperWrapper">
              <div id="chartWrapper">
                <LineChart
                  selectedTimeProfit={selectedTimeProfit}
                  setSelectedTimeProfit={setSelectedTimeProfit}
                  isCustomRange={isCustomRange}
                  startDate={startDate}
                  setStartDate={setStartDate}
                  endDate={endDate}
                  setEndDate={setEndDate}
                  x={x}
                  devices={devices}
                  selectedDatasState={selectedDatasState}
                  selectedDevicesState={selectedDevicesState}
                  selectedShop={selectedShop}
                />
              </div>
              <div id="chartWrapper">
                <LineChartCount
                  selectedTimeCount={selectedTimeCount}
                  setSelectedTimeCount={setSelectedTimeCount}
                  isCustomRange={isCustomRange}
                  startDate={startDate}
                  setStartDate={setStartDate}
                  endDate={endDate}
                  setEndDate={setEndDate}
                  x={x}
                  devices={devices}
                  selectedDatasState={selectedDatasState}
                  selectedDevicesState={selectedDevicesState}
                  selectedShop={selectedShop}
                />
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Admin;
